@import 'styles/mediaQueries.scss';

.toaster {
  position: absolute;
  left: 0;
  right: 0;
  padding: 0;

  @include desktop {
    bottom: 0;
  }

  @include mobile {
    top: 0;
  }

  * {
    pointer-events: all;
  }
}

.toast {
  @include desktop {
    margin: 0 1rem 1rem 1rem;
    animation: slideUp 0.2s ease-out;
  }

  @include mobile {
    margin: 0 0.5rem 0.5rem 0.5rem;
    animation: slideDown 0.2s ease-out;
  }
}

@keyframes slideUp {
  0%   { opacity: 0; transform: translateY(1rem); }
  100% { opacity: 1; transform: translateY(0); }
}

@keyframes slideDown {
  0%   { opacity: 0; transform: translateY(-1rem); }
  100% { opacity: 1; transform: translateY(0); }
}