@import 'styles/mediaQueries.scss';
@import 'styles/interactions.scss';

.grid {
  grid-template-columns: auto 1fr !important;
}

.text.text {
  margin: 0;
  word-break: break-word;
}

.textLarge.textLarge {
  font-weight: 300;
  font-size: 1.5rem;

  @include desktop {
    font-size: 2rem;
  }
}

.iconCell {
  svg {
    display: block;
  }
}

.inline {
  .grid {
    display: inline-grid;
    width: auto;
  }
}

.centerVertical {
  .grid {
    align-items: center;
  }
}

.center {
  text-align: center;
}

.bold {
  font-weight: bold;
}

.interactive {
  @include interactionStates;
}
