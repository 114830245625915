@import '_/styles/mediaQueries.scss';
@import '@ticknovate/frontend-shared/style/interaction.scss';

.content {
  height: calc(100vh - 5rem);
  padding: 2.5rem;

  @include mobile {
    height: calc(100vh - 7.5rem);
    padding: 2.5rem 1.5rem;
  }

  overflow: auto;
  @media (hover: hover) {
    @include scroll_bar;
  }
}
