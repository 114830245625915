@import 'styles/text.scss';

.layout {
  background: RGB(var(--theme_background_ui));
  cursor: pointer;

  &:nth-child(even) {
    background-color: RGBA(var(--theme_background_ui), 0.75);
  }
}

.list {
  padding: 1rem 0.5rem;
  // background: RGB(var(--theme_background_ui));
  cursor: pointer;

  // &:nth-child(even) {
  //   background-color: RGBA(var(--theme_background_ui), 0.75);
  // }
}

@media (max-width: 767px) {
  .list {
    padding: 1rem 0.25rem;
  }
}
