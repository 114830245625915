@import 'styles/text.scss';
@import 'styles/mediaQueries.scss';

@mixin heading {
  display: block;
  flex-grow: 1;
  flex-shrink: 1;
  line-height: unset;
}

.heading-section {
  @include heading;
  @include headingSection;
  margin: 0 0 2rem;

  @include mobile {
    margin-bottom: 1.25rem;
  }
}

.heading-1 {
  @include heading;
  @include headingLarge;
  margin: 0;
}

.heading-2 {
  @include heading;
  @include headingMedium;
  margin: 0;
}

.heading-3 {
  @include heading;
  @include headingSmall;
  margin: 0;
}

.heading-4 {
  @include heading;
  @include headingTiny;
  margin: 0;
}

.brand {
  color: RGB(var(--theme_heading_section));
}

.noMargin {
  margin: 0;
}
