@import 'styles/mediaQueries.scss';

.layout {
  text-align: center;
  flex: 1;

  &.large {
    padding: 2rem;

    @include desktop {
      padding: 5rem;
    }
  }
}

.notificationWrapper {
  display: inline-block;
  width: auto;
}
