@mixin viewSpacing {
  padding: 4rem;

  @media screen and (max-width: 1200px) {
    padding: 2rem;
  }

  @include mobile {
    padding: 1.25rem;
  }
}

@mixin stickToTop {
  position: sticky;

  @include desktop {
    top: 4rem;
  }

  @media screen and (max-width: 1200px) {
    top: 2rem;
  }

  @include mobile {
    top: 0;
    position: relative;
  }
}

@mixin slideContentSpacing {
  bottom: 0;
}
