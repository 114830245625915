.default {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  flex-shrink: 1;
  align-self: stretch;
}

.centered {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  flex-shrink: 1;
  align-self: stretch;
}

.spaced {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  flex-shrink: 1;
  align-self: stretch;

  &> * {
    margin-right: 1rem;

    &:last-child {
      margin-right: 0;
    }
  }
}

.start {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-grow: 1;
  flex-shrink: 1;
  align-self: stretch;
}

.end {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 1;
  flex-shrink: 1;
  align-self: stretch;
}

.stretch {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: stretch;
  flex-grow: 1;
  flex-shrink: 1;
  align-self: stretch;
}

.reverse {
  flex-direction: row-reverse;
}

.spread {
  &> * {
    margin-right: 1rem;

    &:last-child {
      margin-right: 0;
    }
  }
}

.collapse {
  &> *:not(:last-child) {
    margin-bottom: 1rem;
  }
}
