.layout {
  border-radius: 0.5rem;
  background-color: RGB(var(--theme_background_module));
  height: 100%;
}

.layout_dialogue {
  position: relative;
  border-radius: 0;
  padding: 1.5rem;
  background-color: RGB(var(--theme_background_module));
  min-height: 10rem;
}