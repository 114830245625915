@import 'styles/zindex.scss';
@import 'styles/boxes.scss';

.layout {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background: RGBA(var(--theme_background_overlay), 0.4);
  z-index: $zindex_busy_ui_overlay;
  pointer-events: all;
  animation: fadeIn 0.15s ease-out;
}

.layoutInner {
  padding: 3rem;
  width: auto;
  background: RGB(var(--theme_background_module));
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
