@import 'styles/text.scss';
@import 'styles/boxes.scss';

.area {
  @include body;
  @include input_field;
  flex-grow: 1;
  flex-shrink: 1;
  align-self: stretch;
  min-height: 3rem;
  height: 5rem;
  margin: 0;
  width: 100%;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;

  &::-webkit-input-placeholder {
    color: RGB(var(--theme_text_placeholder));
  }

  &:-moz-placeholder {
    color: RGB(var(--theme_text_placeholder));
  }

  &::-moz-placeholder {
    opacity: 1;
    color: RGB(var(--theme_text_placeholder));
  }

  &:-ms-input-placeholder {
    color: RGB(var(--theme_text_placeholder));
  }
}
