@import 'styles/text.scss';
@import 'styles/boxes.scss';
@import 'styles/zindex.scss';
@import 'styles/interactions.scss';

.layout {
  @include text_body;
  width: 100%;
  table-layout: auto;
  border-collapse: collapse;
  margin-bottom: auto;
  position: relative;
  // "Selected row" outline overlaps neighboring elements by 1px and can
  // be cut off for last element in table. Set z-index to counter this.
  z-index: $zindex_ui_foreground;

  th, td {
    padding: 0.625rem 0.25rem;
    min-height: 2.75rem;
  }

  thead {
    tr {
      border-bottom: 1px solid RGB(var(--theme_table_border_thick));

      th {
        @include text_body_bold;
        text-align: left;
        text-transform: capitalize;
        vertical-align: bottom;

        &:first-child {
          padding-left: 0.75rem;
        }

        &:last-child {
          padding-right: 0.75rem;
        }
      }
    }
  }

  tbody {
    tr {
      border-bottom: 1px solid RGB(var(--theme_table_border_thin));

      td {
        text-align: left;

        &:first-child {
          padding-left: 0.75rem;
        }

        &:last-child {
          padding-right: 0.75rem;
        }
      }

      &.details {
        td {
          &:nth-of-type(1) {
            @include text_body_bold;
          }

          &:nth-of-type(2) {
            @include text_body_bold;
            color: RGB(var(--theme_text_success));
          }

          &:nth-of-type(3) {
            @include text_body_bold;
            color: RGB(var(--theme_text_error));
          }

          &:nth-of-type(4) {
            @include text_body;
          }

          .seasons_empty {
            @include text_body;
            color: RGB(var(--theme_text_error));
            margin: 0;
            padding: 0;
          }
        }
      }

      &.content {
        background-color: RGB(var(--theme_background_module));
        border-bottom: 1px solid RGB(var(--theme_table_border_thin));

        td {
          &:nth-of-type(1) {
            @include text_body;
          }
        }
      }
    }
  }
}

.headerStyle_light {
  thead tr th {
    font-weight: normal;
    color: RGB(var(--theme_table_header_title));
    padding: 0.5rem 0.2rem;
  }
}

.noLines thead tr,
.noLines tbody tr {
  border-bottom: none;

  td,
  td:first-child,
  td:last-child {
    padding: 0.5rem 0.2rem;
  }
}

.noRowPadding thead tr,
.noRowPadding tbody tr {
  td,
  td:first-child,
  td:last-child {
    padding: 0;
  }
}

.lightLines thead tr {
  border-bottom-color: RGB(var(--theme_table_border_thin));
}

tbody tr,
thead tr {
  position: relative;

  &.beforeSelectedRow {
    border-bottom-color: transparent;
  }

  &.interactiveRow {
    @include interactionStates;
  }

  &.selectedRow.selectedRow {
    z-index: $zindex_ui_foreground;
    outline: 2px solid RGB(var(--theme_outline_bright));
    outline-offset: -1px;
    background: RGB(var(--theme_background_ui));
  }

  &.shadyRow {
    background-color: RGB(var(--theme_table_row_shaded));
  }
}

.small-width {
  width: 3rem;
}

.noBorderBottom {
  border-bottom: none !important;
}

.arrowCursor {
  cursor: auto;
}
