@import 'styles/mediaQueries.scss';

.layout {
  display: grid;
  grid-template-columns: repeat(7, calc(((100% - 12px)) / 7));
  grid-template-rows: repeat(6, 1fr);
  gap: 2px;
  @include desktop {
    grid-template-columns: repeat(7, 1fr);
    gap: 0.5rem;
  }
  padding: 1rem;
  // background-color: RGB(var(--theme_background_ui));
}
