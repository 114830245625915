@import 'styles/text.scss';
@import 'styles/boxes.scss';

.input {
  @include text_body;
  @include input_field;

  &.small {
    @include input_field_small;
    border: none;
  }

  flex-grow: 1;
  flex-shrink: 1;
  align-self: stretch;
  width: 100%;
  background: #fff;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;

  &::-webkit-input-placeholder {
    color: RGB(var(--theme_text_placeholder));
  }

  &:-moz-placeholder {
    color: RGB(var(--theme_text_placeholder));
  }

  &::-moz-placeholder {
    opacity: 1;
    color: RGB(var(--theme_text_placeholder));
  }

  &:-ms-input-placeholder {
    color: RGB(var(--theme_text_placeholder));
  }

  &:disabled {
    background: RGBA(var(--theme_input_field_disabled), 0.5);
  }

  :global(.stateful-container-with-indicator) & {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}
