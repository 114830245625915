@import '@ticknovate/frontend-shared/style/interaction.scss';
@import '@ticknovate/frontend-shared/style/mediaQueries.scss';

.layout {
  height: auto;
  background-color: RGB(var(--theme_background_ui));
  overflow: auto;
  @media (hover: hover) {
    @include scroll_bar;
  }
  @include desktop {
    height: calc(var(--app-viewport) - 13rem);
  }
}