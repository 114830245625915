.layout {
  font-size: 1rem;
  font-weight: bold;
  flex-shrink: 0;
  color: RGB(var(--theme_ticket_day_text_default));
  text-align: left;
  margin: 0;
  padding: 0 0.25rem;
  background: RGB(var(--theme_ticket_day_background_default));
  border-left: 0.25rem solid RGB(var(--theme_text_light));
  border-right: 0.25rem solid RGB(var(--theme_text_light));

  &.selected {
    color: RGB(var(--theme_ticket_day_text_active));
    background: RGB(var(--theme_ticket_day_background_active));
  }

  &.disabled {
    color: RGB(var(--theme_ticket_day_text_disabled));
    background: RGB(var(--theme_ticket_day_background_disabled));
  }
}
