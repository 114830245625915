@import '@ticknovate/frontend-shared/style/interaction.scss';

.button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-grow: 0;
  flex-shrink: 1;
  align-self: stretch;
  outline: 0;
  padding: 0 1rem;
  margin: 0;
  border-width: 0;
  background-color: RGB(var(--theme_card_default));
  text-decoration: none;
  @include button_hover;
  @include card_shadow;

  &.withBorder {
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 5px;
      background-color: RGB(var(--theme_card_default_bar));
    }
  }

  &.cta {
    background-color: RGB(var(--theme_card_cta));

    &:after {
      background-color: RGB(var(--theme_card_cta_bar));
    }
  }

  &.offer {
    background-color: RGB(var(--theme_background_nav));
  }

  &.warning {
    background-color: RGB(var(--theme_card_warning));

    &:after {
      background-color: RGB(var(--theme_card_warning_bar));
    }
  }

  &.disabled {
    background-color: RGB(var(--theme_card_disabled));

    &:after {
      background-color: RGB(var(--theme_card_disabled_bar));
    }
  }
}