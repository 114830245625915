@mixin interactionStates {
  cursor: pointer;

  &:hover,
  &:focus {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.59;
  }
}

@mixin button_hover {
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
}

@mixin status {
  &.default {
    border-color: RGB(var(--theme_outline_default));
  }

  &.interacting {
    border-color: RGB(var(--theme_outline_interacting));
  }

  &.success {
    border-color: RGB(var(--theme_border_boundary));
  }

  &.warning {
    border-color: RGB(var(--theme_outline_warning));
  }

  &.error {
    border-color: RGB(var(--theme_outline_error));
  }

  &.disabled {
    border-color: RGB(var(--theme_outline_disabled));
    background-color: RGB(var(--theme_button_disabled));
    pointer-events: none;
    opacity: 0.5;

    & > * {
      pointer-events: none;
    }
  }
}
