@mixin body {
  color: RGB(var(--theme_text));
  font-family: 'Roboto', sans-serif;
  font-size: 0.875rem;
  margin: 0 0 1rem 0;
}

@mixin text_body {
  color: RGB(var(--theme_text));
  font-family: var(--font_main);
  font-size: 0.875rem;
}

@mixin text_body_bold {
  color: RGB(var(--theme_text));
  font-family: var(--font_main);
  font-size: 0.875rem;
  font-weight: bold;
}

@mixin text_caption {
  color: RGB(var(--theme_text));
  font-family: var(--font_main);
  font-size: 0.75rem;
}

@mixin no_select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}