@import 'styles/zindex.scss';

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: RGBA(var(--theme_background_overlay), 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: $zindex_busy_ui_overlay;
}
