@import '_/styles/text.scss';
@import '_/styles/boxes.scss';

.list {
  @include text_body;
  @include paragraph;
  list-style: none;
  padding: 0;
  margin: 0;
}

.key {
  @include text_body_bold;

  &::after {
    content: ': ';
  }
}
