.layout {
  position: fixed;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  left: 0;
  bottom: 0;
  width: 100vw;
  padding: 0 1.5rem;
  margin: 0;
}