@import '@ticknovate/frontend-shared/style/interaction.scss';

.content {
  overflow: auto;
  @media (hover: hover) {
    @include scroll_bar;
  }
}

.cta {
  padding: 0 0 1rem 0;
}

/* Fix this properly one day */
@supports (-webkit-touch-callout: none) {
  @media (max-width: 767px) {
    .cta {
      padding: 0 0 3.25rem 0;
    }
  }
}