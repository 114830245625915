@import 'styles/mediaQueries.scss';

$flow-list: column row dense;
$size-list: 'auto' auto, 'fixed' minMax(0, 1fr);
$gap-list: 'none' 0, 'tiny' 0.5rem, 'small' 1rem, 'medium' 2rem, 'large' 3rem, 'huge' 4rem;

:global .grid {
  display: grid;
  width: 100%;
  grid-auto-flow: row;
  align-items: start;

  @each $tuple in $gap-list {
    &.grid-gap-#{nth($tuple, 1)} {
      grid-column-gap: nth($tuple, 2);
      grid-row-gap: nth($tuple, 2);
    }
  }

  @each $tuple in $size-list {
    @for $a from 1 through 12 {
      &.col-#{$a}_cell-#{nth($tuple, 1)} {
        grid-template-columns: repeat(#{$a}, nth($tuple, 2));
      }
    }
  }

  @each $tuple in $size-list {
    @for $a from 1 through 12 {
      &.row-#{$a}_cell-#{nth($tuple, 1)} {
        grid-template-rows: repeat(#{$a}, nth($tuple, 2));
      }
    }
  }

  @each $flow in $flow-list {
    &.flow-#{$flow} {
      grid-auto-flow: #{$flow};
    }
  }
}
