@import '@ticknovate/frontend-shared/style/text.scss';

.button {
  @include text_body;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  flex-shrink: 1;
  align-self: stretch;
  outline: 0;
  margin: 0;
  border-width: 0;
  background-image: var(--gradient);
  border-radius: 0.25rem;
  cursor: pointer;
}