// Define breakpoints that can be used in SCSS:
$breakpoint-mobile: 'screen and (max-width: 768px)';
$breakpoint-tablet: 'screen and (max-width: 1040px)';
$breakpoint-desktop: 'screen and (min-width: 1041px)';

@mixin mobile {
  @media #{$breakpoint-mobile} {
    @content;
  }
}

@mixin tablet {
  @media #{$breakpoint-tablet} {
    @content;
  }
}

@mixin desktop {
  @media #{$breakpoint-desktop} {
    @content;
  }
}

// Export for use in JS. Use with the <MediaQuery> component.
:export {
  mobile: #{$breakpoint-mobile};
  tablet: #{$breakpoint-tablet};
  desktop: #{$breakpoint-desktop};
}
