@import 'styles/text.scss';
@import 'styles/boxes.scss';

.layout {
  flex-grow: 1;
  flex-shrink: 0;
  height: $row_height;
  padding: 0 1rem;
  border-bottom: 1px solid RGB(var(--theme_table_border_thick));

  .heading {
    display: block;
    @include text_body_bold;
  }
}
