@import 'styles/mediaQueries.scss';

.toast {
  margin-bottom: 0.75rem;
  padding: 0.625rem 1.625rem;
  animation: slideIn 0.2s ease-out;

  @include desktop {
    margin-bottom: 1rem;
  }
}

@keyframes slideIn {
  0%   { opacity: 0; transform: translateY(1rem); }
  100% { opacity: 1; transform: translateY(0); }
}
