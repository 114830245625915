@import '@ticknovate/frontend-shared/style/text.scss';
@import '@ticknovate/frontend-shared/style/interaction.scss';

.list {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 6rem;
  overflow-y: hidden;
  overflow-x: auto;

  @media (hover: hover) {
    @include scroll_bar;
  }

  .row {
    display: flex;
    color: RGB(var(--theme_text));
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 0;
    flex-shrink: 0;
    outline: 0;
    width: 6rem;
    height: 6rem;
    padding: 0;
    margin: 0 0.25rem 0 0;
    border-width: 0;
    background-color: RGB(var(--theme_option_default));
    cursor: pointer;

    span {
      font-size: 0.75rem;
      font-weight: bold;
    }

    .warning {
      color: RGB(var(--theme_option_warning_icon));
      font-size: 1.5rem;
      font-weight: bold;
    }

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 3px;
      background-color: RGB(var(--theme_option_default_bar));
    }

    &.active {
      color: RGB(var(--theme_text_light));
      background-color: RGB(var(--theme_option_selected));
    }
  }
}