@import 'styles/zindex.scss';

.wrapper {
  display: inline-block;
  background: RGB(var(--theme_button_disabled));
  border-radius: 100%;
  padding: 6px;
  position: absolute;
  z-index: $zindex_locked_feature_icon;
  margin: 0 !important;

  svg {
    display: block;
  }
}
