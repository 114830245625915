// All z-index values in this app should reference these variables. This keeps
// thing easy to manage by hopefully preventing the issue of having minor UI
// elements floating above overlays or site headers.

// Global elements that should always be on top
$zindex-a11y-skip-link: 9000;
$zindex-cookie-banner: 2000;
$zindex-absolute-widgets: 1001;
$zindex-dropdown: 1000;
$zindex-toast-messages: 900;
$zindex-modal-controls: 850; // Controls above spinner allows the loading action to be cancellable
$zindex_busy_ui_overlay: 800;
$zindex-portal: 700;
$zindex-modal-full-screen: 650;
$zindex-scroll-to-top-button: 620;
$zindex_footer: 600;
$zindex-slide: 500;
$zindex_added_to_basket_popup: 450;
$zindex_header: 400;
$zindex_trade_partner_bar: 350;

// Items that should be in the foreground, but never over the main items above.
// This can be used for dropdowns, floating arrow navigation, etc.
$zindex_ui_foreground: 100;

$zindex_locked_feature_icon: 50;

// Smaller values than those above may just be hardcoded into scss files where
// it makes sense. i.e. ordering a few elements within a widget with z-index
// values between 1 and 10.
