@import '@ticknovate/frontend-shared/style/interaction.scss';

.button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-grow: 1;
  flex-shrink: 1;
  outline: 0;
  padding: 0 1rem;
  margin: 0;
  border-width: 0;
  background-color: RGB(var(--theme_background_nav));
  @include button_hover;
  @include card_shadow;
}