@import 'styles/boxes.scss';

.layout {
  display: flex;
  align-items: stretch;
  width: auto;
  flex-grow: 1;
  flex-shrink: 1;

  &:not(.nude) {
    background-color: RGB(var(--theme_background_input));
    @include rounded_small;
    @include status;
  }

  .message {
    position: absolute;
    bottom: -1.2rem;
    left: 0;
    height: 1rem;
  }

  .indicator {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: RGB(var(--theme_text_light));
    width: 1rem;
    align-self: stretch;
    flex-grow: 0;
    flex-shrink: 0;

    &.default {
      background-color: RGB(var(--theme_input_field_default));
    }

    &.interacting {
      background-color: RGB(var(--theme_input_field_interacting));
    }

    &.error {
      background-color: RGB(var(--theme_input_field_error));
    }

    &.validated {
      background-color: RGB(var(--theme_input_field_success));
    }
  }
}

.message {
  font-size: 0.75rem;

  &.error {
    color: RGB(var(--theme_input_field_error));
  }
}
