@import 'mediaQueries.scss';

@mixin headingSection {
  font-family: var(--font_secondary);
  color: RGB(var(--theme_heading_section));
  font-size: 3rem;
  font-weight: var(--font_section_weight);

  @include mobile {
    font-size: 1.75rem;
  }
}

@mixin headingGrandMobile {
  @include mobile {
    font-family: var(--font_secondary);
    color: RGB(var(--theme_heading_section));
    font-weight: 100;
    font-size: 2.625rem;
  }
}

@mixin headingLarge {
  font-family: var(--font_secondary);
  color: RGB(var(--theme_heading_1));
  font-size: 2rem;
  font-weight: bold;

  @include mobile {
    font-size: 1.5rem;
  }
}

@mixin headingMedium {
  font-family: var(--font_secondary);
  color: RGB(var(--theme_heading_2));
  font-size: 1.5rem;
  font-weight: bold;
}

@mixin headingSmall {
  font-family: var(--font_main);
  color: RGB(var(--theme_heading_3));
  font-size: 1.125rem;
  font-weight: bold;
}

@mixin headingTiny {
  font-family: var(--font_main);
  color: RGB(var(--theme_heading_3));
  font-size: 0.875rem;
  font-weight: bold;
}

@mixin body {
  @include text_body;
  margin: 0 0 1rem 0;
}

@mixin caption {
  color: RGB(var(--theme_text_caption));
  font-size: 0.75rem;
  margin: 0 0 0.5em 0;
}

@mixin tiny {
  color: RGB(var(--theme_text_micro));
  font-size: 0.625rem;
  margin: 0 0 1em 0;
}

@mixin text_body {
  color: RGB(var(--theme_text));
  font-size: 0.875rem;
  line-height: 1.6;
}

@mixin text_body_bold {
  color: RGB(var(--theme_text_bold));
  font-size: 0.875rem;
  font-weight: bold;
}

@mixin text_caption {
  color: RGB(var(--theme_text_caption));
  font-size: 0.75rem;
}

@mixin no_select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
