:global {
  .gridCell {
    display: block;

    &:not(.noChildSpacing) > *:nth-child(n+2),
    // Ignore presence of fieldset for child spacing.
    // <fieldset /> is added for semantics only.
    &:not(.noChildSpacing) > fieldset > *:nth-child(n+2) {
      margin-top: 1rem;
    }

    @for $a from 1 through 12 {
      @for $b from 1 through 12 {
        &.colspan-#{$a}_rowspan-#{$b} {
          grid-column-end: span #{$a};
          grid-row-end: span #{$b};
        }
      }
    }
  }
}
