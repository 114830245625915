@import 'styles/interactions.scss';

.btn {
  @include interactionStates;

  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  border: none;
  background: none;
  padding: 0;
}
