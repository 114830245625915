@import 'styles/text.scss';
@import 'styles/boxes.scss';
@import 'styles/zindex.scss';

.layout {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  align-self: stretch;
  align-items: center;
  justify-content: center;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  .button {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    flex-shrink: 1;
    align-self: stretch;
    align-items: center;
    justify-content: center;
    height: 3rem;
    padding: 0 0.5rem;
    @include ui_gradient;
    @include rounded_small;
    border-color: RGB(var(--theme_table_border_thin));
    margin-bottom: 0;
    cursor: pointer;

    span {
      flex-grow: 1;
      text-align: left;
    }

    &Selected {
      border-bottom-color: transparent;
    }
  }

  .button_small {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    flex-shrink: 1;
    align-self: stretch;
    align-items: center;
    justify-content: center;
    padding: 0.25rem 0.75rem;
    background-color: rgb(255,255,255);
    @include ui_gradient;
    @include rounded_small;
    border-color: RGB(var(--theme_table_border_thin));
    margin-bottom: 0;
    cursor: pointer;

    span {
      flex-grow: 1;
      text-align: left;
    }

    &Selected {
      border-bottom-color: transparent;
    }
  }

  .list {
    position: absolute;
    top: 100%;
    width: 100%;
    max-height: 15rem;
    list-style-type: none;
    margin: 0;
    padding: 0;
    @include rounded_small;
    border-color: RGB(var(--theme_table_border_thin));
    background-color: RGB(var(--theme_background_module));
    z-index: $zindex_ui_foreground;
    overflow-y: scroll;

    & > *:first-child {
      width: 100%;
    }

    .label {
      @include text_body;
      width: 100%;
      cursor: pointer;
    }
  }
}
