// Define breakpoints that can be used in SCSS:
$breakpoint-mobile: 'screen and (max-width: 767px)';
$breakpoint-desktop: 'not ' + $breakpoint-mobile;

@mixin mobile {
  @media #{$breakpoint-mobile} {
    @content;
  }
}

@mixin desktop {
  @media #{$breakpoint-desktop} {
    @content;
  }
}

// Export for use in JS. Use with the <MediaQuery> component.
:export {
  mobile: #{$breakpoint-mobile};
  desktop: #{$breakpoint-desktop};
}
