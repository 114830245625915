@import '@ticknovate/frontend-shared/style/text.scss';
@import '@ticknovate/frontend-shared/style/interaction.scss';

.list {
  max-height: 13.75rem;
  overflow-y: auto;
  overflow-x: hidden;

  @media (hover: hover) {
    @include scroll_bar;
  }

  .row {
    display: flex;
    color: RGB(var(--theme_text));
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-grow: 0;
    flex-shrink: 1;
    outline: 0;
    min-height: 2.25rem;
    padding: 0 0.75rem;
    margin: 0 0 0.25rem 0;
    border-width: 0;
    background-color: RGB(var(--theme_option_default));
    cursor: pointer;

    span {
      font-size: 1rem;
      font-weight: bold;
    }

    .warning {
      color: RGB(var(--theme_option_warning_icon));
      font-size: 1.5rem;
      font-weight: bold;
    }

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 3px;
      background-color: RGB(var(--theme_option_default_bar));
    }

    &.active {
      color: RGB(var(--theme_text_light));
      background-color: RGB(var(--theme_option_selected));
    }
  }
}