@import '@ticknovate/frontend-shared/style/text';

.layout {
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  background-color: RGB(var(--theme_ticket_tile_background_default));
  border-top: 0.125rem solid RGB(var(--theme_ticket_tile_background_border_default));

  .details {
    display: flex;
    align-items: center;
    color: RGB(var(--theme_text_light));
    font-size: 0.75rem;
    margin-top: 0.5rem;
    cursor: pointer;

    & > *:first-child {
      margin-right: 0.2rem;
    }

    &:hover {
      opacity: 0.6;
    }
  }

  &.selected {
    background-color: RGB(var(--theme_ticket_tile_background_active));
    border-top: 0.125rem solid RGB(var(--theme_ticket_tile_background_border_active));
  }

  &.disabled {
    background-color: RGB(var(--theme_ticket_tile_background_disabled));
    border-top: 0.125rem solid RGB(var(--theme_ticket_tile_background_border_disabled));
  }
}
