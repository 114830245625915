:root {
  --app-viewport: 100vh;
}

* {
  position: relative;
  box-sizing: border-box;
}

html {
  position: relative;
  font-size: 16px;
  background: RGB(var(--theme_background_ui));
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: scroll;
}

body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  main {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    min-height: 100vh;
  }

  :global(#portal) {
    position: fixed;
    display: block;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    pointer-events: none;
    z-index: 700;
  }

  :global(#widget) {
    position: fixed;
    display: block;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    pointer-events: none;
    z-index: 800;
  }

  :global(#toast-messages) {
    position: fixed;
    display: block;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    pointer-events: none;
    z-index: 800;
  }
}

body,
input,
textarea {
  font-family: var(--font_main);
}

a {
  color: RGB(var(--theme_text_link));
  text-decoration: none;
}
