.dot {
  color: RGB(var(--theme_text_light));
  width: 1.5rem;
  height: 1.5rem;

  &::before {
    content: '';
    position: absolute;
    display: block;
    top: 0;
    left: -125%;
    width: 100%;
    height: 100%;
    background-color: currentColor;
    border-radius: 100%;
    // animation-name: tick-widget-fade;
    // animation-duration: 1s;
    // animation-iteration-count: infinite;
    // animation-timing-function: ease-in-out;
    animation: tick-widget-fade 1.4s infinite ease-in-out both;
    animation-delay: -0.32s;
  }

  .anim {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: currentColor;
    border-radius: 100%;
    overflow: visible;
    animation: tick-widget-fade 1.4s infinite ease-in-out both;
    animation-delay: -0.16s;
    // animation-name: tick-widget-fade;
    // animation-duration: 1s;
    // animation-iteration-count: infinite;
    // animation-timing-function: ease-in-out;
  }

  &::after {
    content: '';
    position: absolute;
    display: block;
    top: 0;
    right: -125%;
    width: 100%;
    height: 100%;
    background-color: currentColor;
    border-radius: 100%;
    animation: tick-widget-fade 1.4s infinite ease-in-out both;
    // animation-delay: -0.16s;
    // animation-name: tick-widget-fade;
    // animation-duration: 1s;
    // animation-iteration-count: infinite;
    // animation-timing-function: ease-in-out;
  }
}

@keyframes tick-widget-fade {
  0%, 80%, 100% { transform: scale(0) }
  40% { transform: scale(1.0) }
  // 0% {
  //   transform: scale(0);
  // }

  // 25% {
  //   transform: scale(1);
  // }

  // 75% {
  //   transform: scale(1);
  // }

  // 100% {
  //   transform: scale(0);
  // }
}