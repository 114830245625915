@import '@ticknovate/frontend-shared/style/interaction.scss';

.button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 0;
  flex-shrink: 0;
  width: 2rem;
  height: 2rem;
  outline: 0;
  margin: 0;
  padding: 0;
  border-width: 0;
  border-radius: 0.5rem;
  background-color: RGB(var(--theme_background_module));
  // @include button_hover;

  cursor: pointer;
  &:hover svg {
    opacity: 0.8;
  }
}