.layout {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  flex-shrink: 1;
  align-self: stretch;

  &.spread {
    & > * {
      margin-right: 0.25rem;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}