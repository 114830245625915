@import 'styles/text';
@import 'styles/interactions';

.refundsTable {
  table-layout: fixed;
}

.alignTop {
  vertical-align: top;
}

.disabledRow {
  opacity: 0.39;
  pointer-events: none;
}

.refundAmountField {
  width: 6rem;
  margin-left: auto;
}

.success {
  color: RGB(var(--theme_text_success));
}

.addMethodBtn {
  text-align: center;
}

.addMethodBtnTextIconRow {
  @include interactionStates;
  @include text_body;
}

.addMethodBtnDisabled {
  * {
    color: RGB(var(--theme_text_disabled)) !important;
  }
  svg {
    fill: RGB(var(--theme_text_disabled)) !important;
  }
}

.addMethodBtnTextIconRow,
.methodSelect.methodSelect {
  display: inline-flex;
}

.inlineFlex {
  display: inline-flex !important;
}

.methodSelect {
  width: calc(100% - 2rem)
}

.descriptionField {
  resize: vertical;
}

.summaryTable {
  width: 100%;
  border-collapse: collapse;

  th, td {
    border-top: 1px solid RGB(var(--theme_table_border_thick));
    border-bottom: 1px solid RGB(var(--theme_table_border_thick));
    padding: 1rem 0;
    @include headingSmall;
  }
  th {
    text-align: left;
  }
  td {
    text-align: right;
  }
}
