$row_height: 2.25rem;
$input_vertical_padding: 0.45rem;
$input_side_padding: 0.8125rem;

@mixin ui_shadow {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
}

@mixin ui_gradient {
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%,rgba(236, 238, 242, 1) 100%)
}

@mixin status {
  &.default {
    border-color: RGB(var(--theme_input_field_default));
  }

  &.interacting {
    border-color: RGB(var(--theme_input_field_interacting));
  }

  &.validated {
    border-color: RGB(var(--theme_input_field_success));
  }

  &.warning {
    border-color: RGB(var(--theme_input_field_warning));
  }

  &.error {
    border-color: RGB(var(--theme_input_field_error));
  }

  &.disabled {
    border-color: RGB(var(--theme_input_field_disabled));
  }
}

@mixin container_small {
  padding: 0.75rem;
}

@mixin container_large {
  padding: 1.25rem;
}

@mixin rounded_small {
  border-width: 1px;
  border-style: solid;
  border-radius: 0.25rem;
}

@mixin input_field {
  @include rounded_small;
  padding: $input_vertical_padding $input_side_padding;
}

@mixin input_field_small {
  @include rounded_small;
  padding: 0.25rem 0.5rem;
  line-height: normal;
}

@mixin rounded_large {
  border-width: 1px;
  border-style: solid;
  border-radius: 0.5rem;
}

@mixin table_row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
}

@mixin table_cell {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-grow: 1;
  flex-shrink: 1;
  align-self: stretch;
  height: 1.5rem;
  margin: 0.75rem;
}

@mixin paragraph {
  margin: 0.5rem 0;
}
