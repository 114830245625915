@import '@ticknovate/frontend-shared/style/interaction.scss';

.layout {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-grow: 0;
  flex-shrink: 0;
  width: 1rem;
  height: 1rem;
  background-color: RGB(var(--theme_button_disabled));
  border-width: 0;
  border-radius: 0.25rem;
  @include button_hover;

  &.tag {
    background-color: RGB(var(--theme_text));
  }

  &.selected {
    background-color: RGB(var(--theme_button));
  }
}