@mixin button_hover {
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
}

@mixin status {
  &.default {
    border-color: RGB(var(--theme_outline_default));
  }

  &.interacting {
    border-color: RGB(var(--theme_outline_interacting));
  }

  &.success {
    border-color: RGB(var(--theme_outline_success));
  }

  &.warning {
    border-color: RGB(var(--theme_outline_warning));
  }

  &.error {
    border-color: RGB(var(--theme_outline_error));
  }

  &.disabled {
    border-color: RGB(var(--theme_outline_disabled));
    background-color: RGB(var(--theme_button_disabled));
    pointer-events: none;
    opacity: 0.5;

    & > * {
      pointer-events: none;
    }
  }
}

@mixin scroll_bar {
  &::-webkit-scrollbar {
    width: 0.75rem;
    background-color: RGB(var(--theme_background_ui));
  }

  &::-webkit-scrollbar-thumb {
    background-color: RGB(var(--theme_scrollbar));
  }

  scrollbar-color: RGB(var(--theme_scrollbar)) RGB(var(--theme_background_ui));
  scrollbar-width: thin;
}

@mixin modal_shadow {
  filter: drop-shadow(0 1px 5px rgba(0, 0, 0, 0.3));
}

@mixin card_shadow {
  // filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.3));
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
}

@mixin interactionStates {
  cursor: pointer;

  &:hover,
  &:focus {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.59;
  }
}
