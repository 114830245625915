@import 'styles/text.scss';
@import 'styles/boxes.scss';
@import 'styles/zindex.scss';
@import 'styles/interactions.scss';

.layout {
  @include text_body;
  width: 100%;
  position: relative;

  .row {
    padding: 1rem 0.625rem;
    min-height: 2.75rem;
    text-align: left;
    display: flex;

    .rowIconWrapper {
      margin-right: 0.625rem;
      flex: 0 0;
    }

    &:first-child {
      padding-left: 0.75rem;
    }

    &:last-child {
      padding-right: 0.75rem;
    }
  }
}

.row,
.detailsRow {
  border-bottom: 1px solid RGB(var(--theme_table_border_thin));
}

.noLines .row {
  border-bottom: none;

  &:first-child,
  &:last-child {
    padding: 0.5rem 0;
  }
}

.noRowPadding .row {
  padding: 0;
}

.lightLines .row {
  border-bottom-color: RGB(var(--theme_table_border_thin));
}

.row,
.row {
  position: relative;

  &.beforeSelectedRow {
    border-bottom-color: transparent;
  }

  &.interactiveRow {
    @include interactionStates;
  }

  &.selectedRow.selectedRow {
    z-index: $zindex_ui_foreground;
    outline: 2px solid RGB(var(--theme_outline_bright));
    outline-offset: -1px;
    background: RGB(var(--theme_background_ui));
  }

  &.shadyRow {
    background-color: RGB(var(--theme_background_nav));
  }
}

.noBorderBottom {
  border-bottom: none !important;
}

.label {
  font-weight: bold;
  text-transform: capitalize;
}
