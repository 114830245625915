@import 'styles/spacing.scss';
@import 'styles/mediaQueries.scss';

.layout {
  flex: 1;
  @include viewSpacing;
}

.layoutShaded {
  background: RGB(var(--theme_background_module));
}

.inner {
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
}
