@import 'styles/text.scss';

.smallScrollableList {
  max-height: 16rem;
  overflow-y: auto !important;
}

.paddingTop {
  padding-top: 3rem;
}

.placeholder {
  label {
    top: 0.3rem !important;
    left: 0.6rem !important;
  }
}

.card {
  padding: 1rem;
  background-color: RGB(var(--theme_background_ui));

  & > * {
      flex-grow: 0;
      flex-shrink: 0;
      align-self: stretch;
  }

  .type {
      display: inline-block;
      font-weight: bold;
      width: 3rem;
  }
}

.createNew {
  background-color: RGB(var(--theme_background_ui));
}

.createNewLabel {
  @include text_body_bold;
  color: RGB(var(--theme_text_success));
}

.billInfo {
  padding: 2rem 0;
}

.paymentIFrameContainer {
  max-width: 700px;
}

.importantText {
  font-size: 1rem;
  font-weight: bold;
}

.bodyText {
  @include desktop {
    max-width: 50rem;
  }
}

.wpPaymentForm {
  outline: 1px solid #cbcbcb;
  position: relative;
}

.wpLoaderWrapper {
  position: absolute;
  z-index: 1;
  background: rgba(255, 255, 255, 0.9);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 300ms ease-in;
}

.wpLoader {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 4px;
    border: 4px solid #999;
    border-radius: 50%;
    animation: loading-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #999 transparent transparent transparent;

    &:nth-child(1) {
      animation-delay: -0.45s;
    }

    &:nth-child(2) {
      animation-delay: -0.3s;
    }

    &:nth-child(3) {
      animation-delay: -0.15s;
    }
  }

}

@keyframes loading-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


.payLaterSelect {
  max-width: 50%;
  margin-right: 1rem;
}

.payLaterDropdownContainer {
  display: flex;
}

.payLaterEmailButton {
  background-color: RGB(var(--theme_button_go));
  cursor: pointer;
  padding: 0.5rem 1rem;
  color: RGB(var(--theme_text_light));

  &:disabled {
    background-color: RGB(var(--theme_button_disabled));
  }
}
