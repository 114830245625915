.chip {
  flex-shrink: 0;
  flex-grow: 0;
  width: 1rem;
  height: 1rem;
  margin-right: 1rem;
  background-color: RGB(var(--theme_background_module));
  overflow: hidden;

  &::before {
    $badge-size: 1rem;
    $badge-position: calc(-#{$badge-size} / 2);
    content: '';
    position: absolute;
    display: block;
    top: $badge-position;
    right: $badge-position;
    height: $badge-size;
    width: $badge-size;
    background: RGB(var(--theme_date_tile_indicator_combo));
    transform: rotate(-45deg);
  }
}