@import '@ticknovate/frontend-shared/style/text.scss';
@import '@ticknovate/frontend-shared/style/interaction.scss';

.layout {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  flex-shrink: 1;
  align-self: stretch;
  background-color: RGB(var(--theme_background_input));
  border-width: 1px;
  border-style: solid;
  border-radius: 0;
  @include status;

  .message {
    @include text_body;
    position: absolute;
    color: RGB(var(--theme_text_error));
    font-size: 0.75rem;
    bottom: -1.2rem;
    left: 0;
    height: 1rem;
  }

  .indicator {
    display: flex;
    @include text_body;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: RGB(var(--theme_text_light));
    width: 1rem;
    align-self: stretch;
    flex-grow: 0;
    flex-shrink: 0;
    background-color: RGB(var(--theme_outline_default));

    &.interacting {
      background-color: RGB(var(--theme_outline_interacting));
    }

    &.error {
      background-color: RGB(var(--theme_outline_error));
    }

    &.success {
      background-color: RGB(var(--theme_border_boundary));
    }
  }
}
