@import  '@ticknovate/frontend-shared/style/interaction.scss';

.layout {
  align-self: stretch;
  padding: 2rem 1.5rem;
  background-color: RGB(var(--theme_background_module));
  overflow: auto;
  @media (hover: hover) {
    @include scroll_bar;
  }

  .details {
    @include button_hover;
    color: RGB(var(--theme_text_highlight));
    text-transform: uppercase;
    font-size: 0.625rem;
  }
}
