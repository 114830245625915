@import 'styles/interactions.scss';

.layout {
  @include interactionStates;
  display: flex;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 0;
  transition: background 0.1s ease-out;
  max-width: 100%;

  .radio {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 1rem;
    height: 1rem;
    border-radius: 100%;
    flex-shrink: 0;

    &:after {
      content: '';
      display: block;
      width: 0.5rem;
      height: 0.5rem;
      border-radius: 100%;
    }
  }

  .checkbox {
    width: 1rem;
    height: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.25rem;
    flex-shrink: 0;
  }

  .label {
    font-size: 0.875rem;
    line-height: normal;
  }

  &.active {
    .radio:after {
      background-color: RGB(var(--theme_background_ui));
    }
  }

  &.style_default {
    .radio,
    .checkbox {
      margin-right: 1rem;
      background-color: RGB(var(--theme_input_check_inactive));
      border: 1px solid RGB(var(--theme_input_field_default));
    }

    &.active {
      .radio,
      .checkbox {
        background-color: RGB(var(--theme_input_check_active));
        border-color: RGB(var(--theme_input_check_active));
      }
    }
  }

  &.style_tag {
    border-radius: 0.5rem;
    padding: 0.25rem;
    background: RGBA(var(--theme_input_tag_inactive), 0.50);
    color: RGB(var(--theme_text_light));

    &.active {
      background-color: RGB(var(--theme_input_tag_active));
    }

    .label {
      font-size: 0.75rem;
    }

    .radio,
    .checkbox {
      background: RGBA(var(--theme_input_tag_inactive), 0.3);
    }

    .label {
      margin: 0 0.5rem;
    }
  }

  &.large {
    padding: 0.375rem;
    min-height: 2.25rem;

    .checkbox,
    .radio {
      width: 1.5rem;
      height: 1.5rem;

      &:after {
        width: 0.75rem;
        height: 0.75rem;
      }
    }

    .label {
      margin: 0 0.8rem;
      font-size: 0.875rem;
    }
  }

  &.disabled {
    cursor: not-allowed;

    .checkbox,
    .radio {
      background-color: RGB(var(--theme_input_check_disabled));
    }
  }

}
