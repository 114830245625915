@import '_/styles/mediaQueries.scss';

.layout { // Default tile
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-grow: 1;
  flex-shrink: 1;
  align-self: stretch;
  align-items: stretch;
  height: 4rem;
  width: 100%;
  padding: 0.25rem;
  background: RGB(var(--theme_date_tile_default));
  cursor: pointer;

  .day {
    font-size: 1.25rem;
    font-weight: bold;
    color: RGB(var(--theme_date_day_default));
    margin-left: 0.2rem;
    text-align: left;
    @include mobile {
      font-size: 1rem
    }
  }

  &.active {
    background: RGB(var(--theme_date_tile_active));

    .day {
      color: RGB(var(--theme_date_day_active))
    }
  }

  &.between {
    background: RGB(var(--theme_date_tile_outbound));

    .day {
      color: RGB(var(--theme_date_day_outbound))
    }
  }

  &.ghosted { // I am in a different month
    filter: grayscale(80%);
    opacity: 0.6;
  }
}
