.layout {
  ul {
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      display: flex;
      align-items: center;
      justify-content: center;
      color: RGB(var(--theme_text));
      height: 3rem;
      padding: 0 2rem;
      margin: 0 0.5rem 0 0;
      background: RGB(var(--theme_button));
      cursor: pointer;

      &.active {
        background: RGB(var(--theme_background_module));
      }
    }
  }
}
