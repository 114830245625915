@import 'styles/text.scss';
@import 'styles/boxes.scss';
@import 'styles/interactions.scss';

$active_indicator_height: 0.25em;

.wrapper {
  margin: 0 0 1rem 0;
  border-bottom: 1px solid RGB(var(--theme_table_border_thin));
  font-weight: bold;
}

.fadeOutLeft,
.fadeOutRight {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 0.75rem;
  z-index: 1;
  pointer-events: none;
}

.fadeOutLeft {
  left: 0;
  background-image: linear-gradient(to right, currentColor, RGBA(var(--theme_background_ui), 0));
}

.fadeOutRight {
  right: 0;
  background-image: linear-gradient(to left, currentColor, RGBA(var(--theme_background_ui), 0));
}

.nav {
  white-space: nowrap;
  list-style-type: none;
  margin: 0;
  padding: 0;
  text-align: center;
  overflow-x: auto; // Allow scrolling on mobile, when nav too wide

  .alignLeft & { text-align: left; }
  .alignRight & { text-align: right; }
}

.title,
.item {
  display: inline-flex;
  line-height: 1;
  margin: 0 0.75em;
  padding: 0 0 calc(0.75rem + #{$active_indicator_height});
  align-items: center;
  outline-offset: 0 !important;
}

.title {
  margin-left: 0;
  z-index: 2; // Above the fade
}

.item {
  &:not(.itemDisabled) {
    @include interactionStates;
    opacity: 0.5;
  }

  &.itemSelected {
    opacity: 1;

    &:after {
      position: absolute;
      display: block;
      content: '';
      left: 0;
      bottom: 0;
      width: 100%;
      height: $active_indicator_height;
      background-color: RGB(var(--theme_table_row_highlight));
    }
  }

  &.itemDisabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
}
