@import '@ticknovate/frontend-shared/style/interaction.scss';

.layout {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: all;
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: RGBA(var(--theme_background_overlay), 0.5);
  }
}

.content {
  position: absolute;
  pointer-events: all;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  &.in {
    animation-duration: 0.5s;
    animation-name: slideInAction;
    animation-fill-mode: forwards;
  }

  &.out {
    animation-duration: 0.5s;
    animation-name: slideOutAction;
    animation-fill-mode: forwards;
  }

  z-index: 1;
}

.caddy {
  position: absolute;
  display: flex;
  flex-direction: column;
  pointer-events: all;
  top: 2rem;
  left: 0;
  right: 0;
  @include card_shadow;
}

@keyframes slideInAction {
  from {
    transform: translateY(100vh);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes slideOutAction {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(100%);
  }
}
