@import 'styles/text.scss';
@import 'styles/boxes.scss';
@import 'styles/interactions.scss';
@import 'styles/zindex.scss';
@import 'styles/mediaQueries.scss';

.button {
  @include interactionStates;
  padding: 0.45rem 1.5rem;

  @include mobile {
    width: 100%;
  }

  // Misc modifiers
  &.button_inline {
    display: inline-flex;
  }

  &.button_small {
    padding: 0.2rem 1rem;
  }

  &.button_full_width {
    width: 100%;
  }

  // Colors
  &.button_delete {
    border-color: RGB(var(--theme_button_stop));
    background-color: RGB(var(--theme_button_stop));
  }

  &.button_cancel {
    border-color: RGB(var(--theme_button_cancel));
    background-color: RGB(var(--theme_button_cancel));
  }

  &.button_save {
    border-color: RGB(var(--theme_button_go));
    background-color: RGB(var(--theme_button_go));
  }

  &.button_red {
    border-color: RGB(var(--theme_button_warning));
    background-color: RGB(var(--theme_button_warning));
  }

  &.button_blue {
    border-color: RGB(var(--theme_button_brand));
    background-color: RGB(var(--theme_button_brand));
  }

  &.button_create {
    border-color: RGB(var(--theme_button_brand));
    background-color: RGB(var(--theme_button_brand));
  }

  &.button_green {
    border-color: RGB(var(--theme_button_go));
    background-color: RGB(var(--theme_button_go));
  }

  &.button_dark {
    border-color: RGB(var(--theme_button_cancel));
    background-color: RGB(var(--theme_button_cancel));
  }

  &.button_disabled {
    border-color: RGB(var(--theme_button_disabled));
    background-color: RGB(var(--theme_button_disabled));
  }

  &.button_nude {
    border: none;
    background: none;
    padding: 0;
  }

  // Generic styling of nested content
  svg {
    vertical-align: middle;
  }
}

.buttonInner {
  color: RGB(var(--theme_button_inner_text));
  text-align: center;
}

// For loading state, we don't want the width of the button to shrink
// as we show a spinner, and no text. This is achieved by rendering
// the text as hidden, with the spinner positioned over the text.
.loading {
  position: relative;
}

.loadingText {
  visibility: hidden;
}

.loadingSpinner {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: $zindex_ui_foreground;
  display: flex;
  align-items: center;
  justify-content: center;
}
