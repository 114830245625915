@import 'styles/text.scss';
@import 'styles/boxes.scss';

@mixin box {
  padding: 0.875rem 0.625rem;
}

@mixin row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 1rem;
  height: $row_height;
  border-bottom: 1px solid RGB(var(--theme_table_border_thin));
}

@mixin rowSmall {
  display: block;
  padding: 0.5rem 0.25rem;
  border-bottom: 1px solid RGB(var(--theme_table_border_thin));

  &:first-child {
    border-width: 0;
  }
}

.layout {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  align-self: stretch;
  width: 100%;
  overflow: scroll;

  .header {
    @include box;

    .heading {
      display: block;
      @include text_body_bold;
    }
  }

  .list {
    display: block;
    list-style-type: none;
    flex-grow: 1;
    flex-shrink: 1;
    width: 100%;
    margin: 0;
    padding: 0;

    .row {
      @include row;

      &_selected {
        @include row;
        border-bottom-color: transparent;

        &:before {
          position: absolute;
          display: block;
          content: '';
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: RGB(var(--theme_table_row_highlight));
        }

        &:after {
          position: absolute;
          display: block;
          content: '';
          top: 0;
          left: 100%;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 1.5rem 0 1.5rem 1.5rem;
          border-color: transparent transparent transparent RGB(var(--theme_table_row_highlight));
        }
      }

      &_selected_fill {
        @include row;
        border-bottom-color: transparent;

        &:before {
          position: absolute;
          display: block;
          content: '';
          top: 0rem;
          left: -0.5rem;
          right: -0.5rem;
          bottom: 0rem;
          background-color: RGBA(var(--theme_table_row_highlight), 0.25);
        }
      }
    }

    .row_small {
      @include rowSmall;

      &_selected {
        @include rowSmall;
        border-bottom-color: transparent;

        &:before {
          position: absolute;
          display: block;
          content: '';
          top: -0.25rem;
          left: -0.25rem;
          right: -0.25rem;
          bottom: -0.25rem;
          background-color: RGBA(var(--theme_table_row_highlight), 0.25);
          border-radius: 0.25rem;
        }
      }

      &_selected_fill {
        @include rowSmall;
        border-bottom-color: transparent;

        &:before {
          position: absolute;
          display: block;
          content: '';
          top: 0rem;
          left: -0.5rem;
          right: -0.5rem;
          bottom: 0rem;
          background-color: RGBA(var(--theme_table_row_highlight), 0.25);
        }

        &+ .row_small {
          border-bottom-color: transparent;
        }
      }
    }
  }
}
