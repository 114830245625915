@import '@ticknovate/frontend-shared/style/interaction.scss';

.button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  flex-shrink: 1;
  align-self: stretch;
  outline: 0;
  margin: 0;
  padding: 0;
  border-width: 0;
  background-color: transparent;
  @include button_hover;
}