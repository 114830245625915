.layout {
  svg {
    flex-shrink: 0;
    flex-grow: 0;
  }
}

.text,
.textReverse {
  flex-grow: 1;
  flex-shrink: 1;
}

.text {
  &.gap_small {
    margin-right: 0.5rem;
  }
  &.gap_medium {
    margin-right: 1rem;
  }
  &.gap_large {
    margin-right: 1.5rem;
  }
}

.textReverse {
  order: 2;

  &.gap_small {
    margin-left: 0.5rem;
  }
  &.gap_medium {
    margin-left: 1rem;
  }
  &.gap_large {
    margin-left: 1.5rem;
  }
}
