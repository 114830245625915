@import '_/styles/mediaQueries.scss';

.layout { // Default tile
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-grow: 1;
  flex-shrink: 1;
  align-self: stretch;
  align-items: stretch;
  height: 4rem;
  width: 100%;
  padding: 0.25rem;
  background: RGB(var(--theme_date_tile_inactive));
  cursor: pointer;

  .day {
    position: absolute;
    top: 0.25rem;
    left: 0.5rem;
    font-size: 1.25rem;
    font-weight: bold;
    color: RGB(var(--theme_date_day_inactive));
    text-align: left;
    @include mobile {
      font-size: 1rem
    }
  }

  .info {
    display: flex;
    font-size: 0.75rem;
    font-weight: bold;
    color: RGB(var(--theme_date_info_inactive));
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    flex-grow: 1;
    flex-shrink: 1;
    align-self: stretch;
    text-transform: uppercase;
  }

  .price {
    color: RGB(var(--theme_date_price_inactive));
    font-size: 1rem;
    @include mobile {
      font-size: 0.75rem
    }
  }

  &.available {
    background: RGB(var(--theme_date_tile_default));

    .day {
      color: RGB(var(--theme_date_day_default))
    }

    .info {
      color: RGB(var(--theme_date_info_default))
    }

    .price {
      color: RGB(var(--theme_date_price_default))
    }
  }

  &.selected {
    background: RGB(var(--theme_date_tile_active));

    .day {
      color: RGB(var(--theme_date_day_active))
    }

    .info {
      color: RGB(var(--theme_date_info_active))
    }

    .price {
      color: RGB(var(--theme_date_price_active))
    }
  }

  &.departure {
    background: RGB(var(--theme_date_tile_outbound));

    .day {
      color: RGB(var(--theme_date_day_outbound))
    }

    .info {
      color: RGB(var(--theme_date_info_outbound))
    }

    .price {
      color: RGB(var(--theme_date_price_outbound))
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 0.25rem;
      background: RGB(var(--theme_date_tile_indicator_active));
    }
  }

  &.warning {
    background: RGB(var(--theme_date_tile_inactive));

    .day {
      color: RGB(var(--theme_date_day_inactive))
    }

    .info {
      color: RGB(var(--theme_date_info_inactive))
    }

    .price {
      color: RGB(var(--theme_date_price_inactive))
    }
  }

  &.active {
    background: RGB(var(--theme_date_tile_active));

    .day {
      color: RGB(var(--theme_date_day_active))
    }

    .info {
      color: RGB(var(--theme_date_info_active))
    }

    .price {
      color: RGB(var(--theme_date_price_active))
    }
  }

  &.outbound {
    background: RGB(var(--theme_date_tile_outbound));

    .day {
      color: RGB(var(--theme_date_day_outbound))
    }

    .info {
      color: RGB(var(--theme_date_info_outbound))
    }

    .price {
      color: RGB(var(--theme_date_price_outbound))
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 0.25rem;
      background: RGB(var(--theme_date_tile_indicator_active));
    }
  }

  &.inactive {
    background: RGB(var(--theme_date_tile_inactive));
    // pointer-events: none;

    .day {
      color: RGB(var(--theme_date_day_inactive))
    }

    .info {
      color: RGB(var(--theme_date_info_inactive))
    }

    .price {
      color: RGB(var(--theme_date_price_inactive))
    }
  }

  &.ghosted { // I am in a different month
    filter: grayscale(80%);
    opacity: 0.6;
  }

  &.combo {
    overflow: hidden;

    &::before {
      $badge-size: 1rem;
      $badge-position: calc(-#{$badge-size} / 2);

      content: '';
      position: absolute;
      display: block;
      height: $badge-size;
      width: $badge-size;
      top: $badge-position;
      right: $badge-position;
      background: RGB(var(--theme_date_tile_indicator_combo));
      transform: rotate(-45deg);
    }
  }

  &.promo {
    overflow: hidden;

    &::before {
      $badge-size: 1rem;
      $badge-position: calc(-#{$badge-size} / 2);

      content: '';
      position: absolute;
      display: block;
      height: $badge-size;
      width: $badge-size;
      top: $badge-position;
      right: $badge-position;
      background: RGB(var(--theme_date_tile_indicator_promo));
      transform: rotate(-45deg);
    }
  }
}
