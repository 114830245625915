@import '_/styles/mediaQueries.scss';
@import '@ticknovate/frontend-shared/style/interaction.scss';

.layout {
  width: 100vw;
  height: 100vh;
  padding: 2.5rem 4rem;
  overflow: auto;
  @media (hover: hover) {
    @include scroll_bar;
  }

  @include mobile {
    padding: 2.5rem 1.5rem;
  }
}
