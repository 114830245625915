@import 'styles/text.scss';
@import 'styles/boxes.scss';

.layout {
  @include text_body;
  @include paragraph;
}

.last {
  margin-bottom: 0;
}

.center {
  text-align: center;
}

.noMargin {
  margin: 0;
}
