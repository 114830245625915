@import 'styles/mediaQueries.scss';

.toaster {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0 0.75rem;

  @include desktop {
    padding: 0 1.625rem;
  }

  svg {
    display: block;
  }

  * {
    pointer-events: all;
  }
}
